export const smoothScrollToTop = () => {
    /**
     * For browsers that support it use smooth scrolling via scrollTo.
     * Otherwise fall back to scrollTop on the body which is available
     * on Internet Explorer.
     */
    if (window.scrollTo) {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    } else {
        document.body.scrollTop = 0;
    }
};
