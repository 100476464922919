import styled, { css } from "styled-components";
import Link from "next/link";
import RenaultLogoSvg from "../Elements/RenaultLogoSvg";
import React, { useState } from "react";
import { ICarlistData } from "../../lib/queries/CarlistQuery";
import OurCarsHeaderMenu from "./OurCarsHeaderMenu";
import { device } from "../../layout/global-styles/device";
import HandleClickOutside from "../../hooks/HandleClickOutside";

interface HeaderData {
    categories: string[];
    subCategories: string[];
    cars: ICarlistData[];

    // Selected
    category: string;
    subCategory?: string;
}

interface HeaderProps {
    readonly isTopMenu?: boolean;
    readonly isPrimary?: boolean;
    readonly open?: boolean;
    data?: HeaderData;
}

const topMenuItems = [
    {
        title: "Prøvekjør",
        href: "/prøvekjør",
    },
    {
        title: "Finn forhandler",
        href: "/finn-forhandlere",
    },
    {
        title: "Brosjyrer og prislister",
        href: "/brosjyrer",
    },
];

const expandableMenuItems = [
    // {
    //     title: "Oppdag Renault",
    //     href: "",
    // },
    // {
    //     title: "Sponsorater",
    //     href: "",
    // },
    // {
    //     title: "Team Renault",
    //     href: "",
    // },
    // {
    //     title: "Presse",
    //     href: "",
    // },
    // {
    //     title: "Renault Shop",
    //     href: "",
    // },
];

const Header = ({ data }: HeaderProps) => {
    const [openNav, setOpenNav] = useState<boolean>(false);
    const [openPersonbilMenu, setOpenPersonbilMenu] = useState<boolean>(false);
    const [openVarebilMenu, setOpenVarebilMenu] = useState<boolean>(false);

    const [subCategory, setSubCategory] = useState("alle");

    let domNode = HandleClickOutside(() => {
        setOpenNav(false);
        setOpenPersonbilMenu(false);
        setOpenVarebilMenu(false);
    });

    return (
        <>
            <HeaderWrapper ref={domNode}>
                <NavWrapper>
                    <LogoContainer
                        onClick={() => {
                            setOpenPersonbilMenu(false);
                            setOpenVarebilMenu(false);
                            setOpenNav(false);
                        }}
                    >
                        <Link href="/">
                            <a>
                                <RenaultLogoSvg />
                            </a>
                        </Link>
                    </LogoContainer>
                    <NavColumn isPrimary>
                        <NavBar isPrimary>
                            <NavItemsWrapper>
                                <BrandNavItem
                                    isPrimary
                                    onClick={() => {
                                        setOpenPersonbilMenu(false);
                                        setOpenVarebilMenu(false);
                                        setOpenNav(false);
                                    }}
                                ></BrandNavItem>

                                <NavItem
                                    isPrimary
                                    onClick={() => {
                                        setOpenPersonbilMenu(
                                            !openPersonbilMenu
                                        );
                                        setOpenVarebilMenu(false);
                                        setOpenNav(false);
                                        setSubCategory("alle");
                                    }}
                                >
                                    Personbil
                                </NavItem>
                                <NavItem
                                    isPrimary
                                    onClick={() => {
                                        setOpenVarebilMenu(!openVarebilMenu);
                                        setOpenPersonbilMenu(false);
                                        setOpenNav(false);
                                        setSubCategory("alle");
                                    }}
                                >
                                    Varebil
                                </NavItem>
                                <NavItem
                                    isPrimary
                                    onClick={() => {
                                        setOpenPersonbilMenu(false);
                                        setOpenVarebilMenu(false);
                                        setOpenNav(false);
                                    }}
                                >
                                    <Link href={"/kampanjer"}>
                                        <a>Kampanjer</a>
                                    </Link>
                                </NavItem>
                                <NavItem
                                    isPrimary
                                    onClick={() => {
                                        setOpenPersonbilMenu(false);
                                        setOpenVarebilMenu(false);
                                        setOpenNav(false);
                                    }}
                                >
                                    <Link href={"/for-eiere"}>
                                        <a>utforsk</a>
                                    </Link>
                                </NavItem>
                            </NavItemsWrapper>
                        </NavBar>
                    </NavColumn>

                    <NavColumn isPrimary={false}>
                        <NavBar isPrimary={false}>
                            <NavItemsWrapper
                                onClick={() => {
                                    setOpenPersonbilMenu(false);
                                    setOpenVarebilMenu(false);
                                    setOpenNav(false);
                                }}
                            >
                                {topMenuItems &&
                                    topMenuItems.map((item) => {
                                        return (
                                            <NavItem
                                                isPrimary={false}
                                                key={item.title}
                                            >
                                                <Link href={item.href}>
                                                    <a>{item.title}</a>
                                                </Link>
                                            </NavItem>
                                        );
                                    })}
                            </NavItemsWrapper>
                        </NavBar>
                        <BurgerContainer>
                            <BurgerIcon
                                open={openNav}
                                onClick={() => {
                                    setOpenNav(!openNav);
                                    setOpenPersonbilMenu(false);
                                    setOpenVarebilMenu(false);
                                }}
                            >
                                <div />
                                <div />
                                <div />
                            </BurgerIcon>
                        </BurgerContainer>
                    </NavColumn>
                    <ExpandableNav open={openNav}>
                        <ExpandableNavItemsWrapper>
                            <NavItem
                                isPrimary
                                onClick={() => {
                                    setOpenPersonbilMenu(!openPersonbilMenu);
                                    setOpenVarebilMenu(false);
                                    setSubCategory("alle");
                                    setOpenNav(false);
                                }}
                            >
                                Personbil
                            </NavItem>
                            <NavItem
                                isPrimary
                                onClick={() => {
                                    setOpenVarebilMenu(!openVarebilMenu);
                                    setOpenPersonbilMenu(false);
                                    setSubCategory("alle");
                                    setOpenNav(false);
                                }}
                            >
                                Varebil
                            </NavItem>
                            <NavItem
                                isPrimary
                                onClick={() => {
                                    setOpenPersonbilMenu(false);
                                    setOpenVarebilMenu(false);
                                    setOpenNav(false);
                                }}
                            >
                                <Link href={"/kampanjer"}>
                                    <a>Kampanjer</a>
                                </Link>
                            </NavItem>
                            <NavItem
                                isPrimary
                                onClick={() => {
                                    setOpenPersonbilMenu(false);
                                    setOpenVarebilMenu(false);
                                    setOpenNav(false);
                                }}
                            >
                                <Link href={"/for-eiere"}>
                                    <a>Utforsk</a>
                                </Link>
                            </NavItem>
                            {expandableMenuItems &&
                                expandableMenuItems.map((item) => {
                                    return (
                                        <Link
                                            href={item.href}
                                            key={item.title}
                                            passHref
                                        >
                                            <NavItem
                                                onClick={() => {
                                                    setOpenPersonbilMenu(false);
                                                    setOpenVarebilMenu(false);
                                                    setOpenNav(false);
                                                }}
                                            >
                                                <a>{item.title}</a>
                                            </NavItem>
                                        </Link>
                                    );
                                })}
                            {topMenuItems &&
                                topMenuItems.map((item) => {
                                    return (
                                        <NavItem
                                            isTopMenu
                                            key={item.title}
                                            onClick={() => {
                                                setOpenPersonbilMenu(false);
                                                setOpenVarebilMenu(false);
                                                setOpenNav(false);
                                            }}
                                        >
                                            <Link href={item.href}>
                                                <a>{item.title}</a>
                                            </Link>
                                        </NavItem>
                                    );
                                })}
                        </ExpandableNavItemsWrapper>
                    </ExpandableNav>
                    <OurCarsMenu
                        style={
                            openPersonbilMenu || openVarebilMenu
                                ? { display: "flex" }
                                : { display: "none" }
                        }
                    >
                        <OurCarsHeaderMenu
                            openPersonbilMenu={openPersonbilMenu}
                            openVarebilMenu={openVarebilMenu}
                            data={data}
                            subCategory={subCategory}
                            setSubCategory={setSubCategory}
                            setOpenPersonbilMenu={setOpenPersonbilMenu}
                            setOpenVarebilMenu={setOpenVarebilMenu}
                        />
                    </OurCarsMenu>
                </NavWrapper>
            </HeaderWrapper>
        </>
    );
};

export default Header;

const HeaderWrapper = styled.header`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 200;

    background-color: ${(props) => props.theme.black};

    padding: 0 2.5rem;
    height: 5rem;

    @media ${device.laptopM} {
        justify-content: flex-start;
        height: 150px;
        z-index: 100;
    }
`;

const NavWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    margin: 0 auto;

    padding: 0 0.5rem;

    @media ${device.laptopM} {
        justify-content: flex-start;
        height: 100%;

        > div:last-of-type {
            margin-left: auto;
            margin-top: auto;
            margin-bottom: 1rem;
        }
    }
`;

const LogoContainer = styled.div`
    z-index: 99;
    min-width: 2rem;
    margin: auto 0;
    max-width: 32px;

    @media ${device.laptopM} {
        min-width: 4rem;
        margin-right: 4rem;
    }
`;

const NavBar = styled.nav<HeaderProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1rem 0;
    max-width: 1440px;

    ${(props) =>
        props.isPrimary &&
        css`
            font-family: "nouvelr-bold";
            font-size: 1rem;
            li :not(:first-child) {
                text-transform: uppercase;
            }

            @media (max-width: 1279px) {
                li :nth-last-child(-n + 4) {
                    display: none;
                }
            }
        `}

    ${(props) =>
        props.isPrimary === false &&
        css`
            position: absolute;
            top: 0;
            right: 2.5rem;

            font-family: "nouvelr-regular";
            font-size: 0.8rem;

            @media (max-width: 1279px) {
                display: none;
            }
        `}
`;

const NavColumn = styled.div<HeaderProps>`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.isPrimary ? "center" : "flex-end")};
`;

const NavItemsWrapper = styled.ul`
    display: flex;
    list-style: none;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0;
`;

const NavItem = styled.li<HeaderProps>`
    color: ${(props) => props.theme.white};

    &:hover,
    &:active {
        cursor: pointer;
        opacity: 80%;
    }
    ${(props) =>
        props.isTopMenu &&
        css`
            display: block;

            @media ${device.laptopM} {
                display: none;
            }
        `}

    ${(props) =>
        props.isPrimary &&
        css`
            @media ${device.laptopM} {
                :not(:last-child) {
                    margin-right: 4rem;
                }
            }
        `}

    ${(props) =>
        props.isPrimary === false &&
        css`
            padding: 0 1rem;
            border-right: 1px solid ${(props) => props.theme.white};

            :last-child {
                border: none;
                padding-right: 0;
            }
        `}

    a {
        color: ${(props) => props.theme.white};
        text-decoration: none;

        &:hover,
        &:active {
            cursor: pointer;
            opacity: 80%;
        }
    }
`;

const BrandNavItem = styled(NavItem)`
    z-index: 99;
    @media (max-width: 1280px) {
        span :first-of-type {
            display: none;
        }
    }
`;

const BurgerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${device.laptopM} {
        position: absolute;
        top: 4.5rem;
        right: 2.5rem;
        display: none; // temporarily hide secondary menu
    }
`;

const ExpandableNav = styled.nav<HeaderProps>`
    display: ${({ open }) => (open ? "flex" : "none")};
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    text-align: left;
    z-index: 200;
    right: -2.5rem;
    top: 2rem;
    min-height: 33rem;
    margin: 1rem 0;
    min-width: 18rem;
    width: 65vw;
    background-color: ${(props) => props.theme.black};

    font-family: "nouvelr-regular";
    font-size: 1rem;
    padding: 0 2rem;

    @media ${device.mobileL} {
        width: 40vw;
    }

    @media ${device.laptopM} {
        top: 8rem;
        right: 2.5rem;
        width: 18rem;
        display: none; // temporarily hide secondary menu
    }
`;

const ExpandableNavItemsWrapper = styled.ul`
    display: flex;
    list-style: none;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem;
    width: 100%;

    li {
        padding: 1.1rem 0;
    }

    @media ${device.laptopM} {
        li:nth-child(-n + 4) {
            display: none;
        }
    }
`;

const BurgerIcon = styled.button<HeaderProps>`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1;

    width: 24px;
    height: 24px;

    div {
        width: 24px;
        height: 1.2px;
        background: ${(props) => props.theme.white};
        border-radius: 10px;
        transform-origin: 2.5px;
        position: relative;
        transition: 200ms ease;

        &:nth-child(1) {
            transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
            width: ${({ open }) => (open ? "28px" : "24px")};
        }

        &:nth-child(2) {
            transform: ${({ open }) =>
                open ? "translateX(0)" : "translateX(100)"};
            opacity: ${({ open }) => (open ? 0 : 1)};
            width: 16px;
        }

        &:nth-child(3) {
            transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
            width: ${({ open }) => (open ? "28px" : "22px")};
        }
    }

    @media ${device.laptop} {
        width: 24px;
        height: 24px;

        div {
            width: 28px;
            height: 2px;
        }
    }
`;

const OurCarsMenu = styled.div`
    position: absolute;
    top: 0;
    width: 100vw;
    max-width: 100%;

    @media ${device.mobileL} {
    }
`;
