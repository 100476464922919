import React from "react";
import styled from "styled-components";

const Text = styled.a<AlternativLinksStyledComponent>`
    text-decoration: underline;
    text-decoration-color: ${(props) => props.theme.primary};
    text-transform: lowercase;
    color: ${(props) => (props.light ? props.theme.white : props.theme.black)};
    font-size: 1.2em;
    font-family: "nouvelr-bold";
    cursor: pointer;

    &:hover {
        text-decoration-color: ${(props) =>
            props.light ? props.theme.white : props.theme.black};
    }
`;

interface AlternativLinkProps {
    text: string;
    href?: string;
    light?: boolean;
}

interface AlternativLinksStyledComponent {
    light?: boolean;
}

const AlternativLink: React.FC<AlternativLinkProps> = ({
    text,
    light,
    href,
}) => {
    if (typeof light !== "undefined") {
        return (
            <Text href={href} light>
                {text}
            </Text>
        );
    }

    return <Text href={href}>{text}</Text>;
};

export default AlternativLink;
