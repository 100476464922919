import styled from "styled-components";
import { device } from "../../layout/global-styles/device";

export interface ButtonStyledComponent {
    menu?: boolean;
    outline?: boolean;
    fill?: boolean;
}
export interface ButtonProps {
    text: string;
    menu?: boolean;
    outline?: boolean;
    fill?: boolean;
}

const Button = styled.button<ButtonStyledComponent>`
    min-width: 96px;
    font-family: "nouvelr-bold";
    color: ${(props) => props.theme.black};
    background-color: ${(props) => props.theme.primary};
    border: 1px solid ${(props) => props.theme.primary};
    text-decoration: none;
    text-transform: lowercase;

    padding: 14px;
    font-size: ${(props) => (props.menu ? "10px" : "1.1rem")};

    cursor: pointer;
    transition: 200ms ease;

    &:hover {
        background-color: ${(props) => props.theme.secondary};
        border: 1px solid ${(props) => props.theme.secondary};
    }

    @media ${device.mobileS} {
        font-size: ${(props) => (props.menu ? "12px" : "1.1rem")};
    }
`;

const SuperPrimaryButton = ({ text, menu }: ButtonProps) => {
    return <Button menu={menu}>{text}</Button>;
};

export default SuperPrimaryButton;
