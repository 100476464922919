import React from "react";

const RenaultLogoSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 431.7 565"
            fill="#ffffff"
        >
            <path
                d="M248.2,100.9l-21.6,40.4l75.6,141.3L172.7,524.7L43.2,282.5L194.3,0h-43.2L0,282.5L151.1,565h43.2l151.1-282.5
                      L248.2,100.9z"
            />
            <path
                d="M280.6,0h-43.2L86.3,282.5l97.1,181.6l21.6-40.4l-75.6-141.3L259,40.4l129.5,242.1L237.4,565h43.2l151.1-282.5
                      L280.6,0z"
            />
        </svg>
    );
};

export default RenaultLogoSvg;
