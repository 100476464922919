import styled from "styled-components";

const SocialMediaIcon = styled.a`
    padding: 0.5rem 0;
    margin-right: 1.5rem;
`;

const SocialMediaIcons = styled.div`
    justify-content: flex-start;
    display: flex;
    flex-flow: row wrap;
    max-width: 200px;
`;

export { SocialMediaIcons, SocialMediaIcon };
