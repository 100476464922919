import { useEffect, useState } from "react";
import styled from "styled-components";
import { DataLayerPush } from "../lib/tracking/DataLayer";
import AlternativLink from "./Elements/AlternativLink";
import PrimaryButton from "./Elements/PrimaryButton";
import SuperPrimaryButton from "./Elements/SuperPrimaryButton";

const PopUpCookies = () => {
    const [moreInfo, setmoreInfo] = useState(false);
    const [close, setClose] = useState(true);
    const [marketing, setMarketing] = useState(false);
    const [analytics, setAnalytics] = useState(false);

    function setAll(def: boolean) {
        localStorage.setItem("show_cookies_popup", "false");
        localStorage.setItem(
            "cookies_analytics",
            def ? def.toString() : analytics.toString()
        );
        localStorage.setItem(
            "cookies_marketing",
            def ? def.toString() : marketing.toString()
        );

        // Tracking
        if (def) {
            DataLayerPush(window, {
                ActiveGroups: {
                    marked: 0,
                    analytics: 0,
                },
            });

            setTimeout(function () {
                DataLayerPush(window, {
                    optanonAction: "Banner Accept Cookies",
                });
            }, 1000);

            setTimeout(function () {
                DataLayerPush(window, {
                    event: "optanonConsentUpdated",
                });
            }, 1000);
        } else if (marketing) {
            DataLayerPush(window, {
                ActiveGroups: {
                    marked: 0,
                    analytics: -1,
                },
            });
        } else if (analytics) {
            DataLayerPush(window, {
                ActiveGroups: {
                    marked: -1,
                    analytics: 0,
                },
            });
        } else {
            DataLayerPush(window, {
                ActiveGroups: {
                    marked: -1,
                    analytics: -1,
                },
            });
        }
        setTimeout(function () {
            DataLayerPush(window, {
                optanonAction: "Preferences Save Settings",
            });
        }, 1000);

        setTimeout(function () {
            DataLayerPush(window, {
                event: "optanonConsentUpdated",
            });
        }, 1000);
    }

    useEffect(() => {
        setClose(localStorage.getItem("show_cookies_popup") === "false");
        setMarketing(localStorage.getItem("cookies_marketing") === "true");
        setAnalytics(localStorage.getItem("cookies_analytics") === "true");
    }, []);

    return (
        <>
            {close === false && (
                <PopUpWrapper>
                    {moreInfo === false && (
                        <PopUpContent>
                            <Heading>Bruk av informasjonskapsler</Heading>
                            <Text>
                                Vi benytter informasjonskapsler på denne
                                nettsiden. Disse hjelper oss til å gi deg en
                                best mulig opplevelse og forbedre relevansen av
                                vår kommunikasjon med deg. Ved å klikke på
                                "godta" tillater du bruk av disse
                                informasjonskapslene.
                            </Text>
                            <Action>
                                <LinkWrapper
                                    onClick={() => {
                                        setmoreInfo(!moreInfo);
                                    }}
                                >
                                    <AlternativLink
                                        text={"administrere innstillinger"}
                                    />
                                </LinkWrapper>
                                <LinkWrapper
                                    onClick={() => {
                                        setAll(true);
                                        setClose(!close);
                                    }}
                                >
                                    <SuperPrimaryButton
                                        text={"godta"}
                                    ></SuperPrimaryButton>
                                </LinkWrapper>
                            </Action>
                        </PopUpContent>
                    )}
                    {moreInfo === true && (
                        <PopUpContent>
                            <CloseIcon onClick={() => setmoreInfo(!moreInfo)}>
                                <div />
                                <div />
                            </CloseIcon>
                            <Heading>
                                Administrer dine informasjonskapsler
                            </Heading>
                            <Text>
                                Med innstillingene under kan du velge å skru på
                                eller av de forskjellige kategoriene av
                                informasjonskapsler som du ønsker skal være i
                                bruk. De nødvendige informasjonskapslene må
                                alltid være aktive, slik at nettsiden fungerer
                                som normalt. Under “Finn ut mer” ligger mer
                                detaljert informasjon om informasjonskapslene.
                            </Text>
                            <CookieCategoryWrapper>
                                <CookieDescriptionWrapper>
                                    <CookieCategory>
                                        Analyse og statistikk
                                    </CookieCategory>
                                    <CookieDescription>
                                        Disse informasjonskapslene brukes til å
                                        få en bedre forståelse av hvordan
                                        nettsiden blir brukt og hvordan vi kan
                                        forbedre brukeropplevelsen.
                                    </CookieDescription>
                                </CookieDescriptionWrapper>
                                <CoockieToggle>
                                    <CookieToggleBtn>
                                        <CookieSwitch>
                                            <input
                                                type="checkbox"
                                                defaultChecked={analytics}
                                                onClick={(c) => {
                                                    const target =
                                                        c.target as HTMLInputElement;
                                                    localStorage.setItem(
                                                        "cookies_analytics",
                                                        target.checked.toString()
                                                    );
                                                    setAnalytics(
                                                        target.checked
                                                    );
                                                }}
                                            />
                                            <span className="slider" />
                                        </CookieSwitch>
                                    </CookieToggleBtn>
                                </CoockieToggle>
                            </CookieCategoryWrapper>
                            <CookieCategoryWrapper>
                                <CookieDescriptionWrapper>
                                    <CookieCategory>
                                        Markedsføring
                                    </CookieCategory>
                                    <CookieDescription>
                                        Disse informasjonskapslene brukes til å
                                        analysere besøkende på nettsiden.
                                        Intensjonen er å kunne vise reklamer som
                                        er relevant og interessant for brukeren.
                                    </CookieDescription>
                                </CookieDescriptionWrapper>
                                <CoockieToggle>
                                    <CookieToggleBtn>
                                        <CookieSwitch>
                                            <input
                                                type="checkbox"
                                                defaultChecked={marketing}
                                                onClick={(c) => {
                                                    const target =
                                                        c.target as HTMLInputElement;
                                                    localStorage.setItem(
                                                        "cookies_marketing",
                                                        target.checked.toString()
                                                    );
                                                    setMarketing(
                                                        target.checked
                                                    );
                                                }}
                                            />
                                            <span className="slider" />
                                        </CookieSwitch>
                                    </CookieToggleBtn>
                                </CoockieToggle>
                            </CookieCategoryWrapper>
                            <CookieCategoryWrapper>
                                <CookieDescriptionWrapper>
                                    <CookieCategory>
                                        Nødvendige informasjonskapsler
                                    </CookieCategory>
                                    <CookieDescription>
                                        Disse informasjonskapslene brukes til å
                                        huske tidligere handlinger som valgte
                                        innstillinger for informasjonskapsler.
                                    </CookieDescription>
                                </CookieDescriptionWrapper>
                                <CoockieToggle>
                                    <CookieToggleBtn>
                                        <CookieSwitch>
                                            <input
                                                type="checkbox"
                                                checked={true}
                                                disabled
                                            />
                                            <span className="slider" />
                                        </CookieSwitch>
                                    </CookieToggleBtn>
                                </CoockieToggle>
                            </CookieCategoryWrapper>
                            <Action>
                                <AlternativLink
                                    href="/personvern-og-copyright"
                                    text={"finn ut mer"}
                                />

                                <LinkWrapper
                                    onClick={() => {
                                        setAll(false);
                                        setClose(!close);
                                    }}
                                >
                                    <PrimaryButton text={"lagre"} />
                                </LinkWrapper>
                                <LinkWrapper
                                    onClick={() => {
                                        setAll(true);
                                        setClose(!close);
                                    }}
                                >
                                    <SuperPrimaryButton text={"godta alle"} />
                                </LinkWrapper>
                            </Action>
                        </PopUpContent>
                    )}
                </PopUpWrapper>
            )}
        </>
    );
};

export default PopUpCookies;

const PopUpWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0%;
    left: 0%;
    z-index: 999;
`;
const PopUpContent = styled.div`
    background-color: ${(props) => props.theme.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid ${(props) => props.theme.light};
    max-width: 500px;
    min-width: 350px;
    padding: 30px;
`;
const Heading = styled.h3`
    text-align: center;
    margin-top: 0%;
`;
const Text = styled.p`
    margin: 16px 0;
    text-align: center;
    max-width: 600px;
`;
const Action = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    flex-direction: row;
    margin-top: 5%;
`;

const CookieCategoryWrapper = styled.div`
    display: flex;
    padding: 0;
    margin: 0 8px 10px;
    align-items: center;
    justify-content: center;
`;
const CookieDescriptionWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`;
const CookieCategory = styled.h4`
    padding-left: 0;
    margin-bottom: 5px;
`;
const CookieDescription = styled.p`
    margin: 0;
`;
const CoockieToggle = styled.div`
    margin-left: auto;
    padding-left: 32px;
`;
const CookieToggleBtn = styled.div`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 32px;
`;
const CookieSwitch = styled.label`
    height: 34px;
    position: relative;
    display: inline-block;
    width: 60px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    input:checked + .slider {
        background-color: ${(props) => props.theme.primary};
    }
    input:focus + .slider {
        box-shadow: 0 0 1px ${(props) => props.theme.primary};
    }
    input:checked + .slider:before {
        transform: translateX(26px);
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${(props) => props.theme.neutral};
        transition: 0.4s;
        border-radius: 34px;

        ::before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: ${(props) => props.theme.white};
            transition: 0.4s;
            border-radius: 50%;
        }
    }
`;

const CloseIcon = styled.button`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    background: transparent;
    border: none;
    cursor: pointer;

    top: 5%;
    right: 3%;
    position: absolute;

    z-index: 100;

    width: 24px;
    height: 24px;

    div {
        width: 28px;
        height: 3px;
        background: ${(props) => props.theme.black};
        border-radius: 5px;
        transform-origin: 5px;
        position: relative;
        transition: 200ms ease;

        &:nth-child(1) {
            transform: rotate(45deg);
        }

        &:nth-child(2) {
            transform: rotate(-45deg);
        }
    }

    @media (min-width: 900px) {
        width: 24px;
        height: 24px;

        div {
            width: 28px;
            height: 3px;
        }
    }
`;

const LinkWrapper = styled.a`
    cursor: pointer;
`;
