import { createGlobalStyle } from "styled-components";
import { device } from "./device";

export interface ITheme {
    black: string;
    white: string;
    primary: string;
    secondary: string;
    darker: string;
    base: string;
    neutral: string;
    medium: string;
    light: string;
    blue: string;
}

export interface IThemeWrapper {
    theme: ITheme;
}

export const theme: ITheme = {
    black: "#000000",
    white: "#FFFFFF",
    primary: "#EFDF00",
    secondary: "#F8EB4C",
    darker: "#3E3F40",
    base: "#656666",
    neutral: "#8C8C8B",
    medium: "#D9D9D6",
    light: "#F2F2F2",

    blue: "#0091DA",
};

const GlobalStyle = createGlobalStyle<IThemeWrapper>`

  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  }

    body {
      margin: 0 auto;
      color: ${(props) => props.theme.black};
      font-family: 'nouvelr-regular';
      font-size: 14px;

    }
    h1 {
      font-size: 28px;
      font-family: 'nouvelr-regular';
      line-height: 0.8em;

      @media ${device.tablet}{
        font-size: 50px;
      }
    }
    h2 {
      font-size: 24px;
      font-family: 'nouvelr-regular';

      @media ${device.tablet}{
        font-size: 28px;
      }
    }
    h3{
      font-size: 20px;
      font-family: 'nouvelr-regular';
    }
    h4{
      font-size: 18px;
      font-family: 'nouvelr-regular';
    }
    button{
      font-family: 'nouvelr-bold';
    }

    .bold{
      font-family: 'nouvelr-bold';
    }
    .small{
      font-size: 14px;
    }
    .space10{
      margin-top: 10px;
    }
    a{
      color: #000000;
      text-decoration: underline;
    }
    .list{
      margin-left: 20px;
      margin-top: 10px;
      margin-bottom: 10px;

      li{
        margin-bottom: 5px;
      }
    }
    .underline{
      text-decoration: underline;
    }
    .table{
      margin: 1rem;

      td{
        padding: 10px;
      }
      th{
        background-color: #000000;
        color: #ffffff;
        padding: 15px;
        font-family: "nouvelr-bold";
      }
      tr:nth-child(odd){
        background-color: #F2F2F2;
      }
    }


    #aktuelt,
    #sikkerhet,
    #omtaler,
    #rekkeviddekalkulator,
    #ladetidkalkulator,
    #kampanje,
    #earlybird,
    #hotspot,
    #slide,
    #scroll,
    #leasingkampanje
    {
        scroll-margin-top: 5rem;
    }
  
  @font-face {
      font-family: nouvelr-regular;
      src: url('/fonts/nouvelr/NouvelR-Regular.otf') format('opentype'),
           url('/fonts/nouvelr/NouvelR-Regular.ttf') format('truetype');
      font-display: auto;
      font-weight: normal;
      font-style: normal;
  }
  
  @font-face {
      font-family: nouvelr-bold;
      src: url('/fonts/nouvelr/NouvelR-Bold.otf') format('opentype'),
           url('/fonts/nouvelr/NouvelR-Bold.ttf') format('truetype');
      font-display: auto;
      font-weight: normal;
      font-style: normal;
  }
   .warning-text,
   .duty-text {
        margin-top: 0.5rem;
        font-size: 80%;
    }
  `;

export default GlobalStyle;
