import { useRouter } from "next/router";
import React, { useEffect } from "react";

import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import PopUpCookies from "../components/PopUpCookies";
import { GetCarList } from "../lib/queries/CarlistQuery";
import { DataLayerPush } from "../lib/tracking/DataLayer";

const Layout = ({ children }) => {
    const router = useRouter();

    const carData = {
        categories: ["personbil", "varebil"],
        subCategories: ["alle", "elbil", "plug-in hybrid", "hybrid"],
        cars: GetCarList(),
        category: "personbil",
        subCategory: "alle",
    };

    useEffect(() => {
        const routeChangeComplete = () => {
            let vpv = router.asPath + "?";
            for (const k in router.query) {
                const v = router.query[k];
                vpv += k + "=" + v + "&";
            }
            DataLayerPush(window, {
                vpv: vpv,
                event: "vpvFired",
            });
        };
        router.events.on("routeChangeComplete", routeChangeComplete);

        return () => {
            router.events.off("routeChangeComplete", routeChangeComplete);
        };
    });

    return (
        <>
            <Header data={carData} />
            <PopUpCookies />
            {children}
            <Footer />
        </>
    );
};

export default Layout;
