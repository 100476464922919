import { useQuery } from "@apollo/client";
import { gql } from "apollo-boost";
import StaticApolloClient from "../StaticApolloClient";

export interface IDealerListData {
    [x: string]: any /* index signature for property forEach */;
    dealerName: string;
    dealerFylke: string;
    depId: string;
    dealerAddress: {
        address: string;
        postNumber: string;
        postalCode: string;
        phone: string;
        email: string;
        website: string;
        lat: string | number;
        lng: string | number;
    };
    services: [];
    dealerInfo: {
        postnromrademin1: string | number;
        postnromrademin2: string | number;
        postnromrademin3: string | number;
        postnromrademin4: string | number;
        postnromrademin5: string | number;
        postnromrademin6: string | number;
        postnromrademin7: string | number;
        postnromrademin8: string | number;
        postnromrademin9: string | number;
        postnromrademin10: string | number;
        postnromrademaks1: string | number;
        postnromrademaks2: string | number;
        postnromrademaks3: string | number;
        postnromrademaks4: string | number;
        postnromrademaks5: string | number;
        postnromrademaks6: string | number;
        postnromrademaks7: string | number;
        postnromrademaks8: string | number;
        postnromrademaks9: string | number;
        postnromrademaks10: string | number;
    };
}

const DEALER_QUERY = () => gql`
    query MyQuery {
        forhandlere(where: { offsetPagination: { size: 60 } }) {
            nodes {
                title
                dealer {
                    depId
                    fylke
                    postnromrademin1
                    postnromrademin2
                    postnromrademin3
                    postnromrademin4
                    postnromrademin5
                    postnromrademin6
                    postnromrademin7
                    postnromrademin8
                    postnromrademin9
                    postnromrademin10
                    postnromrademaks1
                    postnromrademaks2
                    postnromrademaks3
                    postnromrademaks4
                    postnromrademaks5
                    postnromrademaks6
                    postnromrademaks7
                    postnromrademaks8
                    postnromrademaks9
                    postnromrademaks10
                    tjenester
                    lat
                    lng
                    linkToForhandlerside
                }
                dealerSideBar {
                    adresse
                    postnummer
                    poststed
                    telefon
                    epost
                    hjemmeside
                }
            }
        }
    }
`;

const transform = (dealerListData) => {
    let dealerList: IDealerListData[] = [];
    if (dealerListData && dealerListData.data) {
        const nodes = dealerListData?.data?.forhandlere.nodes;
        for (const c of nodes) {
            const forhandlerWebsite =
                c.dealer.linkToForhandlerside ?? c.dealerSideBar.hjemmeside;

            dealerList.push({
                dealerName: c.title,
                dealerFylke: c.dealer.fylke,
                depId: c.dealer.depId,
                dealerAddress: {
                    address: c.dealerSideBar.adresse,
                    postNumber: c.dealerSideBar.postnummer,
                    postalCode: c.dealerSideBar.poststed,
                    phone: c.dealerSideBar.telefon,
                    email: c.dealerSideBar.epost,
                    website: forhandlerWebsite,
                    lat: c.dealer.lat,
                    lng: c.dealer.lng,
                },
                services: c.dealer.tjenester,
                dealerInfo: {
                    postnromrademin1: c.dealer.postnromrademin1,
                    postnromrademin2: c.dealer.postnromrademin2,
                    postnromrademin3: c.dealer.postnromrademin3,
                    postnromrademin4: c.dealer.postnromrademin4,
                    postnromrademin5: c.dealer.postnromrademin5,
                    postnromrademin6: c.dealer.postnromrademin6,
                    postnromrademin7: c.dealer.postnromrademin7,
                    postnromrademin8: c.dealer.postnromrademin8,
                    postnromrademin9: c.dealer.postnromrademin9,
                    postnromrademin10: c.dealer.postnromrademin10,
                    postnromrademaks1: c.dealer.postnromrademaks1,
                    postnromrademaks2: c.dealer.postnromrademaks2,
                    postnromrademaks3: c.dealer.postnromrademaks3,
                    postnromrademaks4: c.dealer.postnromrademaks4,
                    postnromrademaks5: c.dealer.postnromrademaks5,
                    postnromrademaks6: c.dealer.postnromrademaks6,
                    postnromrademaks7: c.dealer.postnromrademaks7,
                    postnromrademaks8: c.dealer.postnromrademaks8,
                    postnromrademaks9: c.dealer.postnromrademaks9,
                    postnromrademaks10: c.dealer.postnromrademaks10,
                },
            });
        }
        return dealerList;
    }
    return null;
};

export const GetDealers = () => {
    const dealerListData = useQuery(DEALER_QUERY());
    return transform(dealerListData);
};

export const GetDealersSSR = async () => {
    const dealerListData = await StaticApolloClient.query({
        fetchPolicy: "no-cache",
        query: DEALER_QUERY(),
    });
    return transform(dealerListData);
};
