export interface IFylkerData {
    fylke: string;
    id: string;
}

export const fylkerData = [
    { fylke: "Agder", id: "agder" },
    { fylke: "Akershus", id: "akershus" },
    { fylke: "Buskerud", id: "buskerud" },
    { fylke: "Finnmark", id: "finnmark" },
    { fylke: "Innlandet", id: "innlandet" },
    { fylke: "Møre og Romsdal", id: "more-og-romsdal" },
    { fylke: "Nordland", id: "nordland" },
    { fylke: "Oslo", id: "oslo" },
    { fylke: "Rogaland", id: "rogaland" },
    { fylke: "Telemark", id: "telemark" },
    { fylke: "Troms", id: "troms" },
    { fylke: "Trøndelag", id: "trondelag" },
    { fylke: "Vestfold", id: "vestfold" },
    { fylke: "Vestland", id: "vestland" },
    { fylke: "Østfold", id: "ostfold" },
];
