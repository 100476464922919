import styled from "styled-components";
import { device } from "../../layout/global-styles/device";
import { ICarlistData } from "../../lib/queries/CarlistQuery";
import CarCard from "./CarCard";

interface HeaderProps {
    readonly openCarMenu?: boolean;
}

const OurCarsHeaderMenu = ({
    data,
    openPersonbilMenu,
    openVarebilMenu,
    subCategory,
    setSubCategory,
    setOpenPersonbilMenu,
    setOpenVarebilMenu,
}) => {
    const personbilerList: ICarlistData[] = [];
    const varebilerList: ICarlistData[] = [];

    const carCategories: string[] = data.categories;
    const personbilerSubCategories: string[] = data.subCategories.slice(0, 2);
    const varebilerSubCategories: string[] = data.subCategories.slice(0, 2);

    for (const elem of data.cars) {
        const tags = elem.tags;
        if (tags.length && tags.includes("personbiler") && !elem.noList) {
            personbilerList.push(elem);
        } else if (tags.length && tags.includes("varebiler") && !elem.noList) {
            varebilerList.push(elem);
        }
    }

    return (
        <ExpandableNavItemsWrapper>
            <ExpandableNav>
                <Categories>
                    {openPersonbilMenu && (
                        <>
                            {personbilerSubCategories &&
                                personbilerSubCategories.map((c) => (
                                    <Category
                                        key={c}
                                        onClick={() => setSubCategory(c)}
                                        className={
                                            c === subCategory
                                                ? "active-category"
                                                : ""
                                        }
                                    >
                                        {c}
                                    </Category>
                                ))}
                        </>
                    )}
                    {openVarebilMenu && (
                        <>
                            {varebilerSubCategories &&
                                varebilerSubCategories.map((c) => (
                                    <Category
                                        key={c}
                                        onClick={() => setSubCategory(c)}
                                        className={
                                            c === subCategory
                                                ? "active-category"
                                                : ""
                                        }
                                    >
                                        {c}
                                    </Category>
                                ))}
                        </>
                    )}
                </Categories>
                <CardWrapper>
                    {openPersonbilMenu && (
                        <CarCard
                            cars={personbilerList}
                            selectedSubCategory={subCategory}
                            carCategories={carCategories[0]}
                            setOpenPersonbilMenu={setOpenPersonbilMenu}
                            setOpenVarebilMenu={setOpenVarebilMenu}
                        />
                    )}
                    {openVarebilMenu && (
                        <CarCard
                            cars={varebilerList}
                            selectedSubCategory={subCategory}
                            carCategories={carCategories[1]}
                            setOpenPersonbilMenu={setOpenPersonbilMenu}
                            setOpenVarebilMenu={setOpenVarebilMenu}
                        />
                    )}
                </CardWrapper>
            </ExpandableNav>
        </ExpandableNavItemsWrapper>
    );
};

export default OurCarsHeaderMenu;

const ExpandableNavItemsWrapper = styled.ul`
    display: flex;
    list-style: none;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-between;
    position: absolute;
    padding: 2rem;
    width: 100%;
    z-index: 0;

    @media ${device.laptop} {
        width: 100vw;
        max-width: 100%;
    }
`;

const ExpandableNav = styled.nav<HeaderProps>`
    z-index: 90;
    display: flex;

    flex-flow: column nowrap;

    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;

    text-align: left;

    top: 5rem;

    min-height: 100vh;
    min-width: 18rem;
    width: 65vw;

    right: -2rem;
    padding: 2rem;
    background-color: ${(props) => props.theme.white};

    font-family: "nouvelr-regular";
    font-size: 1.25rem;

    @media ${device.laptop} {
        top: 10rem;
        left: 0;

        min-height: 45vh;
        width: 85vw;
        max-width: 1440px;
    }
`;

const CardWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    align-items: center;
    justify-content: space-around;
    flex-flow: row wrap;
    width: 100%;

    a {
        text-decoration: none;
    }
`;

const Categories = styled.div`
    display: flex;

    /* flex-flow: column nowrap; */
    flex-flow: column nowrap;

    min-width: 10rem;

    @media ${device.laptop} {
        flex-flow: row wrap;
    }

    .active-category {
        color: ${(props) => props.theme.black};
        border-bottom: 1px solid ${(props) => props.theme.primary};
    }
`;

const Category = styled.p`
    color: ${(props) => props.theme.neutral};
    margin: 1rem;
    width: fit-content;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    transition: 150ms ease;

    &:hover {
        color: ${(props) => props.theme.black};
    }
`;
