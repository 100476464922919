import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";
import { device } from "../../layout/global-styles/device";
import { smoothScrollToTop } from "../../lib/window";
import facebookImage from "../../public/icons/facebook.svg";
import instagramImage from "../../public/icons/instagram.svg";
import linkedInImage from "../../public/icons/linkedin.svg";
import twitterImage from "../../public/icons/Twitter_X.svg";
import youtubeImage from "../../public/icons/youtube.svg";
import { Centered } from "../Centered";
import ChevronUp from "../icons/ChevronUp";
import LinkToTop from "./LinkToTop";
import { SocialMediaIcon, SocialMediaIcons } from "./SocialMediaIcons";

const StyledFooter = styled.footer`
    padding: 1rem 2rem;

    @media ${device.laptop} {
        padding: 1rem 4rem;
    }

    a {
        text-decoration: none;
        color: ${(props) => props.theme.black};
    }
`;

const CenteredDiv = styled(Centered)`
    cursor: pointer;
    padding: 1rem;
`;

const Columns = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem;
`;

const LinksColumns = styled(Columns)`
    padding-bottom: 1rem;
`;

const Column = styled.div`
    margin: 0 1rem 1rem 0;
    min-width: 200px;

    :last-child {
        margin-right: 0;
    }
`;

const ESGColumn = styled.div`
    margin: 0 1rem 1rem 0;
    min-width: 200px;
    max-width: 300px;

    :last-child {
        margin-right: 0;
    }
`;

const LinkHeading = styled.h3`
    font-size: 1.875em;
    margin-bottom: 0.7rem;
    @media (max-width: 768px) {
        padding: 15px 0;
    } ;
`;

const List = styled.ul`
    margin: 0;
    padding: 0;
    li {
        list-style: none;
        margin-bottom: 0.7rem;
    }
`;

const BottomLinks = styled(Columns)`
    align-items: center;
    min-height: 80px;

    ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        li {
            padding: 0.5rem 0;

            @media ${device.tablet} {
                border-right: 1px solid ${(props) => props.theme.black};
                padding: 0 1rem;

                :first-child {
                    padding-left: 0;
                }

                :last-child {
                    border: none;
                    padding-right: 0;
                }
            }
        }
    }
    @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: flex-start;
    } ;
`;

const DividingLine = styled.div`
    position: absolute;
    border-bottom: 2px solid black;
    left: 0;
    width: 100%;
`;

const Disclaimer = styled.p`
    font-size: 1em;
`;

const CopyrightInfo = styled.p`
    font-weight: bold;
    font-size: 1.25em;
`;
const InlineLink = styled.a`
    text-decoration: underline ${(props) => props.theme.primary} !important;
`;

const Footer = () => {
    return (
        <StyledFooter>
            <CenteredDiv onClick={() => smoothScrollToTop()}>
                <ChevronUp />
                <LinkToTop>til toppen</LinkToTop>
            </CenteredDiv>
            <LinksColumns>
                <Column>
                    <LinkHeading>sosiale medier</LinkHeading>
                    <SocialMediaIcons>
                        <SocialMediaIcon
                            href="https://www.linkedin.com/company/renault-norge-rbi-norge-as-?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A1233360%2Cidx%3A2-1-2%2CtarId%3A1454678418009%2Ctas%3ARenault%20norge"
                            target="_blank"
                        >
                            <Image
                                unoptimized={true}
                                alt="Linkedin"
                                src={linkedInImage}
                            />
                        </SocialMediaIcon>
                        <SocialMediaIcon
                            href="https://www.facebook.com/Renault.Norge"
                            target="_blank"
                        >
                            <Image
                                unoptimized={true}
                                alt="Facebook"
                                src={facebookImage}
                            />
                        </SocialMediaIcon>
                        <SocialMediaIcon
                            href="https://www.youtube.com/user/RENAULTNORGE"
                            target="_blank"
                        >
                            <Image
                                unoptimized={true}
                                alt="YouTube"
                                src={youtubeImage}
                            />
                        </SocialMediaIcon>
                        <SocialMediaIcon
                            href="https://www.instagram.com/renaultnorge/?hl=nb"
                            target="_blank"
                        >
                            <Image
                                unoptimized={true}
                                alt="Instagram"
                                src={instagramImage}
                            />
                        </SocialMediaIcon>
                        <SocialMediaIcon
                            href="https://twitter.com/Renaultnorge"
                            target="_blank"
                        >
                            <Image
                                unoptimized={true}
                                alt="X (Twitter)"
                                src={twitterImage}
                            />
                        </SocialMediaIcon>
                    </SocialMediaIcons>
                </Column>
                <Column>
                    <LinkHeading>Renault Norge</LinkHeading>
                    <List>
                        <li>
                            <Link href="/for-eiere">
                                <a>utforsk</a>
                            </Link>
                        </li>
                        <li>
                            <Link href="/kontakt-oss">
                                <a>kontakt oss</a>
                            </Link>
                        </li>
                        <li>
                            <Link href="https://www.mynewsdesk.com/no/renaultnorge/">
                                <a>presse</a>
                            </Link>
                        </li>
                        <li>
                            <Link href="https://rn-nordic-no.weselect.com/">
                                <a>jobb hos oss</a>
                            </Link>
                        </li>
                    </List>
                </Column>
                <Column>
                    <LinkHeading>annet</LinkHeading>
                    <List>
                        <li>
                            <Link href="https://vikingredning.no/renault-veihjelp/">
                                <a target="_blank" rel="noopener noreferrer">
                                    bestill veihjelp
                                </a>
                            </Link>
                        </li>
                        <li>
                            <Link href="/brosjyrer">
                                <a>brosjyrer og prislister</a>
                            </Link>
                        </li>
                    </List>
                </Column>
                <ESGColumn>
                    <LinkHeading>RN Norge NUF</LinkHeading>
                    <List>
                        <li>
                            ESG informasjon om Renault Group SAS internasjonalt
                            finnes på deres nettsted.
                            <Link
                                href="https://www.renaultgroup.com/en/our-commitments/our-environmental-and-societal-strategy/"
                                passHref
                            >
                                <InlineLink> {"renaultgroup.com"}</InlineLink>
                            </Link>
                        </li>
                    </List>
                </ESGColumn>
            </LinksColumns>

            <DividingLine />

            <BottomLinks>
                <Column>
                    <List>
                        <li>
                            <a href="/personvern-og-copyright">
                                Personvern og copyright
                            </a>
                        </li>
                        <li>
                            <a href="/personopplysninger">Personopplysninger</a>
                        </li>
                        <li>
                            <a href="/informasjonskapsler">
                                Informasjonskapsler
                            </a>
                        </li>
                    </List>
                </Column>
                <Column>
                    <Disclaimer>
                        Renault tar forbehold om skrivefeil.
                    </Disclaimer>
                </Column>
                <Column>
                    <CopyrightInfo>© Renault 2017-2024</CopyrightInfo>
                </Column>
            </BottomLinks>
        </StyledFooter>
    );
};

export default Footer;
