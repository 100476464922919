import React from "react";
import App from "next/app";
import Head from "next/head";
import { ThemeProvider } from "styled-components";
import { ApolloProvider } from "@apollo/react-hooks";
import withApollo from "../hooks/withApollo";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import GlobalStyle, { theme } from "../layout/global-styles/globalStyles";
import Layout from "../layout/Layout";
import { DealerProvider } from "../context/DealerContext";
import { withPasswordProtect } from "@storyofams/next-password-protect";

interface IProps {
    apollo: ApolloClient<NormalizedCacheObject>;
}

class MyApp extends App<IProps> {
    render() {
        // instead of creating a client here, we use the rehydrated apollo client provided by our own withApollo provider.
        const { Component, pageProps, apollo } = this.props;
        return (
            <React.Fragment>
                <Head>
                    <title>Renault Norge</title>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1"
                    />
                    <meta name="title" content="Renault Norge" />
                    <meta
                        name="description"
                        content="Velkommen til Renault Norge. Få oversikt over bilmodeller, forsikring, finansiering, forhandlere, m.m. Book prøvekjøring i dag!"
                    />

                    <link rel="shortcut icon" href="/favicon.svg" />
                    <link
                        rel="apple-touch-icon"
                        sizes="180x180"
                        href="/apple-touch-icon.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="32x32"
                        href="/favicon-32x32.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="16x16"
                        href="/favicon-16x16.png?"
                    />
                </Head>
                {/* adds the apollo provider to provide it's children with the apollo scope. */}
                <ApolloProvider client={apollo}>
                    <ThemeProvider theme={theme}>
                        <Layout>
                            <GlobalStyle />
                            <DealerProvider>
                                <Component {...pageProps} />
                            </DealerProvider>
                        </Layout>
                    </ThemeProvider>
                </ApolloProvider>
            </React.Fragment>
        );
    }
}

// Before: export default App;
export default process.env.PASSWORD_PROTECT
    ? withPasswordProtect(withApollo(MyApp), {
          loginComponentProps: {
              buttonBackgroundColor: "#000",
              buttonColor: "#fff",
              logo: "https://renault.no/admin/wp-content/uploads/2021/09/R-Megane-E-Tech_Electric_Listebilde.png",
          },
          loginApiUrl: "/api/login",
          checkApiUrl: "/api/passwordCheck",
      })
    : withApollo(MyApp);
