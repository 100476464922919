import React, { useEffect, useState } from "react";
import { formHelpers } from "../lib/formHelpers";
import { fylkerData, IFylkerData } from "../lib/fylkerData";
import { GetDealers, IDealerListData } from "../lib/queries/DealerQuery";

const DealerContext = React.createContext(null);

export const DealerProvider = (props) => {
    /* Get full list of dealers from Query */
    const dealers = GetDealers() as any;
    /* This only converts a type so it works as we expect further */
    const fullDealersList = dealers as IDealerListData;

    /* setState for fylke */
    const [selectedFylke, setSelectedFylke] = useState<IFylkerData>();

    /* When it receives a postNumber from the input field,
    it calls the helper getPostalNumber to find the nearest dealer */
    const [postNumber, setPostNumber] = useState<string>();

    /* Helper setState for the form radio selection */
    const [dealerPreviouslySelected, setDealerPreviouslySelected] =
        useState(false);

    useEffect(() => {
        if (postNumber) {
            const filteredDealersHelper = formHelpers.getPostalNumber(
                postNumber,
                fullDealersList
            );
            setFilteredDealers(filteredDealersHelper);
            if (filteredDealersHelper && filteredDealersHelper.length > 0) {
                const matchedFylke = fylkerData.find(
                    (fylke) => fylke.id === filteredDealersHelper[0].dealerFylke
                );
                setSelectedFylke(matchedFylke);
            }
            setDealerPreviouslySelected(true);
        }
        return () => {
            setPostNumber(undefined);
        };
    }, [postNumber, fullDealersList]);

    const [filteredDealers, setFilteredDealers] = useState() as any[];
    const [selectedDealer, setSelectedDealer] =
        useState<IDealerListData>(fullDealersList);

    return (
        <DealerContext.Provider
            value={{
                fullDealersList,
                postNumber,
                setPostNumber,
                filteredDealers,
                setFilteredDealers,
                selectedDealer,
                setSelectedDealer,
                selectedFylke,
                setSelectedFylke,
                dealerPreviouslySelected,
                setDealerPreviouslySelected,
            }}
        >
            {props.children}
        </DealerContext.Provider>
    );
};

export default DealerContext;
