import { useEffect, useRef } from "react";

let HandleClickOutside = (handler) => {
    let domNode = useRef(null);

    useEffect(() => {
        let innderHandler = (event) => {
            if (!domNode.current.contains(event.target)) {
                handler();
            }
        };
        document.addEventListener("mousedown", innderHandler);

        return () => {
            document.removeEventListener("mousedown", innderHandler);
        };
    });

    return domNode;
};

export default HandleClickOutside;
