import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import StaticApolloClient from "../StaticApolloClient";

const CARLIST_QUERY = gql`
    query {
        carLists(where: { offsetPagination: { size: 30 } }) {
            nodes {
                carListACF {
                    price
                    kampanjepris
                    cleanName
                    order
                    notestdrive
                    hasTeaser
                    priceDisclaimer
                    dontListItem
                    setLink
                }
                slug
                featuredImage {
                    node {
                        mediaItemUrl
                    }
                }
                categories {
                    nodes {
                        name
                    }
                }
            }
        }
    }
`;

const CARLIST_SLUG_ONLY_QUERY = gql`
    query {
        carLists(where: { offsetPagination: { size: 30 } }) {
            nodes {
                title
                slug
                carListACF {
                    dontListItem
                    hasTeaser
                }
            }
        }
    }
`;

export interface ICarlistData {
    [x: string]: any;
    price: string;
    campaignPrice?: string;
    name: string;
    slug: string;
    image: string;
    tags: string[];
    video?: string;
    hoverText?: string;
    link: string;
    setLink?: string;
    order: string | null;
    equipmentList?: string;
    priceDisclaimer?: string;
    noListTestDrive?: boolean;
    noList?: boolean;
    teaser?: boolean;
}

const primaries = ["varebiler", "personbiler"];
const transform = (cars) => {
    let carlist: ICarlistData[] = [];
    if (cars && cars.data) {
        const nodes = cars?.data?.carLists.nodes;
        for (const c of nodes) {
            // Get all the categories in lower case for comparsion
            const tags = c.categories.nodes.map((c) => c.name.toLowerCase());
            // Find the main category
            const primary = tags.find((cc) => {
                return primaries.includes(cc);
            });
            let dir = primary;
            // Rewrite the /hybrid/ urls they are not actual paths
            if (!c.slug.match(/hybrid/)) {
                // Locate the path for the car
                tags.find((cc) => {
                    if (primary && tags.length === 1) {
                        dir = primary;
                    } else {
                        dir = cc;
                    }
                    return cc;
                });
            }

            carlist.push({
                price: c.carListACF.price,
                campaignPrice: c.carListACF.kampanjepris,
                priceDisclaimer: c.carListACF.priceDisclaimer,
                name: c.carListACF.cleanName,
                slug: c.slug,
                image: c.featuredImage.node.mediaItemUrl,
                tags: tags,
                link: `/${dir}/${c.slug}`,
                setLink: c.carListACF.setLink,
                order: c.carListACF.order,
                noListTestDrive: c.carListACF.notestdrive,
                noList: c.carListACF.dontListItem,
                teaser: c.carListACF.hasTeaser,
            });

            carlist.sort((a, b) => {
                if (a.order < b.order) {
                    return -1;
                }
                if (a.order > b.order) {
                    return 1;
                }
                return 0;
            });
        }
    }
    return carlist;
};

export const GetCarListSSR = async () => {
    const query = await StaticApolloClient.query({
        fetchPolicy: "no-cache",
        query: CARLIST_QUERY,
    });
    return transform(query);
};

export const GetOnlyCarSlugList = async () => {
    const cars = await StaticApolloClient.query({
        fetchPolicy: "no-cache",
        query: CARLIST_SLUG_ONLY_QUERY,
    });
    let carlist = [];
    if (cars && cars.data) {
        const nodes = cars?.data?.carLists.nodes;
        for (const c of nodes) {
            carlist.push({
                slug: c.slug,
                dontListItem: c.carListACF.dontListItem,
                title: c.title,
                teaser: c.carListACF.hasTeaser,
            });
        }
    }
    return carlist;
};

export const GetCarList = () => {
    const cars = useQuery(CARLIST_QUERY);
    return transform(cars);
};
