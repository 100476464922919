import Image from "next/image";
import React from "react";
import styled from "styled-components";
import { ICarlistData } from "../../lib/queries/CarlistQuery";
import { device } from "../../layout/global-styles/device";
import ReactHtmlParser from "react-html-parser";

interface CarCardProps {
    cars: ICarlistData[];
    selectedSubCategory: any;
    carCategories: any;
    setOpenVarebilMenu?: any;
    setOpenPersonbilMenu?: any;
}

const CarCard = ({
    cars,
    selectedSubCategory,
    carCategories,
    setOpenPersonbilMenu,
    setOpenVarebilMenu,
}: CarCardProps) => {
    /* Change filtering name from "alle" so they match with broad category */
    if (selectedSubCategory === "alle") {
        if (carCategories === "personbil") {
            selectedSubCategory = "personbiler";
        }

        if (carCategories === "varebil") {
            selectedSubCategory = "varebiler";
        }
    }

    const setHref = (car) => {
        if (car.teaser) {
            return null;
        }
        if (car.link && !car.setLink) {
            return car.link;
        }
        if (car.setLink) {
            return car.setLink;
        }
        return null;
    };

    return (
        <CardsWrapper>
            {cars &&
                cars.map((car) => {
                    return (
                        car.tags.includes(selectedSubCategory) && (
                            <Card key={car.name}>
                                <a href={setHref(car)}>
                                    <ContentContainer
                                        onClick={() => {
                                            setOpenPersonbilMenu(false);
                                            setOpenVarebilMenu(false);
                                        }}
                                    >
                                        <ImageDiv>
                                            <Image
                                                src={car.image}
                                                layout="fill"
                                                objectFit="contain"
                                                alt={car.name}
                                                placeholder="blur"
                                                blurDataURL="data:image/png;base64,"
                                            />
                                        </ImageDiv>
                                        <CarName>
                                            {ReactHtmlParser(car.name)}
                                        </CarName>
                                    </ContentContainer>
                                </a>
                            </Card>
                        )
                    );
                })}
        </CardsWrapper>
    );
};

export default CarCard;

const CardsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 280px));
    grid-gap: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
`;

const Card = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const CarName = styled.p`
    color: ${(props) => props.theme.black};
    font-size: 1rem;
    text-transform: uppercase;
    padding: 0 0.5rem;
`;

const ImageDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 150px;
    width: 200px;

    @media ${device.mobileL} {
        height: 150px;
        width: 250px;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
`;
