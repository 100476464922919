import styled from "styled-components";
import { ButtonProps, ButtonStyledComponent } from "./SuperPrimaryButton";

const Button = styled.button<ButtonStyledComponent>`
    min-width: 96px;
    font-family: "nouvelr-bold";
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.black};
    border: 1px solid ${(props) => props.theme.black};
    text-decoration: none;
    text-transform: lowercase;
    width: ${(props) => (props.fill ? "100%" : "unset")};

    padding: 14px;
    font-size: ${(props) => (props.menu ? "12px" : "1.1rem")};

    cursor: pointer;
    transition: 200ms ease;

    &:hover {
        color: ${(props) => props.theme.black};
        background-color: ${(props) => props.theme.primary};
        border: 1px solid ${(props) => props.theme.primary};
    }
`;

const PrimaryButton = ({ text, menu, fill }: ButtonProps) => {
    return (
        <Button menu={menu} fill={fill}>
            {text}
        </Button>
    );
};

export default PrimaryButton;
