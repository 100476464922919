interface ChevronProps {
    stroke?: string
    width?: string
    height?: string
}

const ChevronUp = (props: ChevronProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || "61.616"}
            height={props.height || "21.571"}
            viewBox="0 0 61.616 21.571"
        >
            <path
                id="Path_51"
                data-name="Path 51"
                d="M680.519,225.929l29.475,19.534,31.056-19.534"
                transform="translate(741.582 246.654) rotate(180)"
                fill="none"
                stroke={props.stroke || "#efdf00"}
                strokeWidth="2"
            />
        </svg>
    );
};

export default ChevronUp;
