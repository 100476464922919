import { IDealerListData } from "./queries/DealerQuery";

export namespace formHelpers {
    /* logic imported from previous website */
    export const getPostalNumber = (
        postalNumber: string,
        dealers: IDealerListData
    ) => {
        const showDealerList = [];

        for (let i = 0; i < dealers.length; i++) {
            if (
                postalNumber >= dealers[i].dealerInfo.postnromrademin1 &&
                postalNumber <= dealers[i].dealerInfo.postnromrademaks1
            ) {
                showDealerList.push(dealers[i]);
            } else if (
                postalNumber >= dealers[i].dealerInfo.postnromrademin2 &&
                postalNumber <= dealers[i].dealerInfo.postnromrademaks2
            ) {
                showDealerList.push(dealers[i]);
            } else if (
                postalNumber >= dealers[i].dealerInfo.postnromrademin3 &&
                postalNumber <= dealers[i].dealerInfo.postnromrademaks3
            ) {
                showDealerList.push(dealers[i]);
            } else if (
                postalNumber >= dealers[i].dealerInfo.postnromrademin4 &&
                postalNumber <= dealers[i].dealerInfo.postnromrademaks4
            ) {
                showDealerList.push(dealers[i]);
            } else if (
                postalNumber >= dealers[i].dealerInfo.postnromrademin5 &&
                postalNumber <= dealers[i].dealerInfo.postnromrademaks5
            ) {
                showDealerList.push(dealers[i]);
            } else if (
                postalNumber >= dealers[i].dealerInfo.postnromrademin6 &&
                postalNumber <= dealers[i].dealerInfo.postnromrademaks6
            ) {
                showDealerList.push(dealers[i]);
            } else if (
                postalNumber >= dealers[i].dealerInfo.postnromrademin7 &&
                postalNumber <= dealers[i].dealerInfo.postnromrademaks7
            ) {
                showDealerList.push(dealers[i]);
            } else if (
                postalNumber >= dealers[i].dealerInfo.postnromrademin8 &&
                postalNumber <= dealers[i].dealerInfo.postnromrademaks8
            ) {
                showDealerList.push(dealers[i]);
            } else if (
                postalNumber >= dealers[i].dealerInfo.postnromrademin9 &&
                postalNumber <= dealers[i].dealerInfo.postnromrademaks9
            ) {
                showDealerList.push(dealers[i]);
            } else if (
                postalNumber >= dealers[i].dealerInfo.postnromrademin10 &&
                postalNumber <= dealers[i].dealerInfo.postnromrademaks10
            ) {
                showDealerList.push(dealers[i]);
            }
        }

        return showDealerList;
    };
}
