import withApollo from "next-with-apollo";
import ApolloClient, { InMemoryCache } from "apollo-boost";
import { GetDomain } from "../lib/domain";

export default withApollo(({ initialState }) => {
    let domain = process.browser ? window.location.hostname : GetDomain();
    if (domain.match(/localhost/)) {
        domain = "dev.renault.no";
    }
    return new ApolloClient({
        uri: `https://${domain}/admin/index.php?graphql`,
        cache: new InMemoryCache().restore(initialState || {}),
    });
});
